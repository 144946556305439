<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        A solution of the salt
        <chemical-latex content="AB2(s)" />
        is found to have equilibrium concentrations for
        <chemical-latex content="A^{2+}(aq)" />
        and
        <chemical-latex content="B^{-}(aq)" />
        of
        <number-value :value="A2conc" unit="\text{M}" />
        and
        <number-value :value="Bconc" unit="\text{M}" />
        , respectively. Determine the equilibrium constant,
        <stemble-latex :content="'$\\text{K}_{\\text{sp}},$'" />
        for this salt under these conditions.
      </p>

      <calculation-input
        v-model="inputs.Ksp"
        :prepend-text="'$\\text{K}_{\\mathrm{sp}}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question123',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Ksp: null,
      },
    };
  },
  computed: {
    A2conc() {
      return this.taskState.getValueBySymbol('A2conc').content;
    },
    Bconc() {
      return this.taskState.getValueBySymbol('Bconc').content;
    },
  },
};
</script>
